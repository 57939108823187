import { Centrifuge } from "centrifuge";
import HttpClient from "@/api";

class ChatService {
  constructor() {
    this.url = import.meta.env.VITE_APP_WS || "";
  }

  newConnectionStart(token) {
    const centrifuge = new Centrifuge(this.url, { token });
    centrifuge.on("connected", (connectCtx) => {
      console.log("Connected:", connectCtx);
    });

    centrifuge.on("disconnected", (disconnectCtx) => {
      console.log("Disconnected:", disconnectCtx);
    });

    return centrifuge;
  }

  connectionStart(token) {
    if (!this.centrifuge) {
      this.centrifuge = this.newConnectionStart(token);
    }

    return this.centrifuge;
  }

  async getToken() {
    if (localStorage.tokenWs) {
      return localStorage.tokenWs;
    }

    try {
      const response = await HttpClient.get("api/me/broadcast-token");
      const token = response.data.data.token;
      localStorage.setItem("tokenWs", token);
      return token;
    } catch (error) {
      throw new Error("Ошибка получения токена");
    }
  }

  async connectOnChat() {
    const token = await this.getToken();
    const centrifuge = this.connectionStart(token);
    centrifuge.connect();
  }

  disconnect() {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  }
}

export default new ChatService();
