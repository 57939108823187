<script>
import { vMaska } from "maska";
export default {
  name: "AppInput",
  directives: { maska: vMaska },
  emits: ["update:modelValue", "click", "focusoutHandler", "clickHandler"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: [Boolean, String, Object],
      default: undefined,
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },

    size: {
      type: String,
      default: "middle",
    },

    clear: {
      type: Boolean,
      default: undefined,
    },

    mask: {
      type: String,
      default: undefined,
    },

    maskTokens: {
      type: String,
      default: undefined,
    },

    inputMode: {
      type: String,
      default: "text",
    },

    maxLength: {
      type: Number,
      default: 255,
    },

    chatSearch: {
      type: Boolean,
      default: false,
    },

    counter: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedValue: {
      get() {
        return this.modelValue;
      },

      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },

    counterValue() {
      return `${this.formattedValue.length}/${this.maxLength}`;
    },

    maxLengthValue() {
      return this.formattedValue.length === this.maxLength;
    },

    isVisibleCloseButton() {
      return this.formattedValue === null
        ? false
        : this.formattedValue.length && this.clear && !this.chatSearch;
    },
  },

  methods: {
    updateInputValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },

    clearInput() {
      this.$emit("update:modelValue", "");
    },

    inputClick() {
      if (this.disabled) {
        this.$emit("click");

        return null;
      }
      this.$emit("clickHandler");
    },

    triggerClick() {
      this.$refs.input.focus();
    },

    handleFocusOut() {
      this.$emit("focusoutHandler");
    },
  },
};
</script>

<template>
  <div class="app-input" @click="inputClick">
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="app-input_required">*</span>
      <span v-if="notRequired" class="app-input_not-required">
        - Необязательно
      </span>
      <span
        v-if="counter"
        :class="{ counter_max: maxLengthValue }"
        class="app-input_counter counter"
      >
        {{ counterValue }}
      </span>
    </label>

    <div class="app-input__wrapper">
      <div
        class="input"
        :class="{
          input_error: error && !hideError,
          input_small: size === 'small',
          input_disabled: disabled,
        }"
      >
        <slot name="left" />

        <input
          :id="id"
          ref="input"
          v-model="formattedValue"
          v-maska
          :type="type"
          :disabled="disabled"
          :placeholder="placeholder"
          :data-maska="mask"
          :data-maska-tokens="maskTokens"
          :maxlength="maxLength"
          :inputmode="inputMode"
          @input="updateInputValue"
          @focusout="handleFocusOut"
        />

        <button v-if="isVisibleCloseButton" type="button" @click="clearInput">
          <i class="icon-close" />
        </button>

        <slot name="right" />
      </div>

      <slot name="options"></slot>
    </div>

    <span v-if="error && !hideError" class="error">
      {{ error }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.app-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;
  position: relative;

  &_required {
    @include s-text();
    color: $text-error;
  }

  &_not-required {
    @include s-text();
    color: $text-fifth;
  }

  .counter {
    position: absolute;
    right: 4px;
    color: $text-third;

    &_max {
      color: $text-error;
    }
  }

  > label {
    color: $text-third;
    @include s-text();
    padding: 0 0 0 ($base-space);
  }

  &__wrapper {
    width: 100%;
    position: relative;

    > .input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $base-space;
      border: 1px solid $stroke-third;
      border-radius: 16px;
      height: 56px;
      padding: 0 ($base-space * 5);

      i {
        width: 24px;
        height: 24px;
        background: $text-primary;
      }

      > input {
        width: 100%;
        height: 100%;
        @include m-text();
        color: $text-primary;
        padding: 0;
        background: none;
        border: none;
        outline: none;

        &::placeholder {
          color: $text-fifth;
        }
      }

      > button {
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;

        > i {
          width: 24px;
          height: 24px;
          background: $text-fifth;
        }
      }

      &:focus-within {
        border-color: $stroke-accent;
      }

      &_error {
        border-color: $stroke-error;
      }

      &_small {
        height: 44px;
        padding: 0 ($base-space * 3);

        i {
          width: 20px;
          height: 20px;
        }

        > button {
          width: 20px;
          height: 20px;
        }
      }

      &_disabled {
        > input {
          cursor: not-allowed;
        }
      }
    }
  }

  > span {
    padding: 0 0 0 ($base-space);
  }
}
</style>
