import HttpClient from "@/api";
import chatService from "@/services/ChatService";

const state = {
  isLoadingNotification: false,
  notificationsList: null,
  perPage: 15,
  unreadNotification: 0,
  unreadMessages: 0,
  currentNotification: null,
};

const mutations = {
  SET_NOTIFICATIONS(store, payload) {
    store.notificationsList = [...(store.notificationsList ?? []), ...payload];
  },

  SET_UNREAD_NOTIFICATIONS_COUNT(store, payload) {
    store.unreadNotification = payload.countUnreadNotification;
  },

  RESET_UNREAD_NOTIFICATIONS_COUNT(store) {
    store.unreadNotification = 0;
  },

  READ_NOTIFICATION(store) {
    store.unreadNotification = store.unreadNotification - 1;
  },

  SET_UNREAD_MESSAGES_COUNT(store, payload) {
    store.unreadMessages = payload.countUnreadMessage;
  },

  SET_CURRENT_NOTIFICATION(store, payload) {
    store.currentNotification = payload;
  },

  CLEAR_NOTIFICATION(store) {
    store.currentNotification = null;
  },

  RESET_NOTIFICATIONS(store) {
    store.notificationsList = null;
  },

  TOGGLE_LOADING_NOTIFICATIONS(store, payload) {
    store.isLoadingInfoUser = payload ?? !store.isLoadingInfoUser;
  },
};

const actions = {
  async getUnreadCount(store) {
    try {
      const response = await HttpClient.get(`api/me/notifications/count`);
      store.commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
        countUnreadNotification: response.data.unread_notifications_count,
      });
      store.commit("SET_UNREAD_MESSAGES_COUNT", {
        countUnreadMessage: response.data.unread_messages_count,
      });
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить количество непрочитанных уведомлений")
      );
    }
  },

  async readNotifications() {
    try {
      await HttpClient.post(`api/me/notification/read`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось прочитать уведомления"));
    }
  },

  async deleteNotifications() {
    try {
      await HttpClient.delete(`api/me/notifications`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось удалить уведомления"));
    }
  },

  async loadNotificationById(store, data) {
    try {
      const response = await HttpClient.get(`api/me/notifications/${data.id}`);
      store.commit("SET_CURRENT_NOTIFICATION", response.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить информацию об уведомлении")
      );
    }
  },

  async deleteNotification(store, data) {
    try {
      await HttpClient.delete(`api/me/notifications/${data.notification_id}`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось удалить уведомление"));
    }
  },

  async getAllNotifications(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", true);
      const params = {
        per_page: store.perPage,
        page: payload.page,
      };
      const response = await HttpClient.get(`api/me/notifications/all`, {
        params,
      });

      store.commit("SET_NOTIFICATIONS", response.data.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить список непрочитанных уведомлений")
      );
    } finally {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", false);
    }
  },

  async getUnreadNotifications(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", true);
      const params = {
        per_page: store.perPage,
        page: payload.page,
      };
      const response = await HttpClient.get(`api/me/notifications/unread`, {
        params,
      });

      store.commit("SET_NOTIFICATIONS", response.data.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить список непрочитанных уведомлений")
      );
    } finally {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", false);
    }
  },

  async shutdown() {
    chatService.disconnect();
  },

  async init({ dispatch }) {
    await chatService.connectOnChat();
    await dispatch("listener");
  },

  async listener({ rootState, dispatch }) {
    const userId = rootState.user.user.id;
    const centrifuge = chatService.connectionStart();

    centrifuge.on("disconnect", (ctx) => {
      console.warn(`Disconnected from Centrifugo: ${ctx.reason}`);
      setTimeout(() => {
        console.log("Reconnecting to Centrifugo...");
        centrifuge.connect();
      }, 5000); // Переподключение через 5 секунд
    });

    centrifuge.on("connect", () => {
      console.log("Connected to Centrifugo successfully.");
    });

    const notifications = `notifications:user#user::${userId}`;
    const direct = `chats:user:direct#user::${userId}`;
    const channel = `chats:user:group#user::${userId}`;

    const notificationsSubscription = centrifuge.newSubscription(notifications);

    notificationsSubscription.on("publication", (ctx) => {
      dispatch("actionsChat", ctx.data);
    });

    notificationsSubscription.on("error", (errCtx) => {
      console.error(`Error in notifications subscription: ${errCtx}`);
    });

    const directSubscription = centrifuge.newSubscription(direct);

    directSubscription.on("publication", (ctx) => {
      dispatch("actionsChat", ctx.data);
      dispatch("chat/actionsChat", ctx.data, { root: true });
    });

    directSubscription.on("error", (errCtx) => {
      console.error(`Error in direct chat subscription: ${errCtx}`);
    });

    const groupSubscription = centrifuge.newSubscription(channel);

    groupSubscription.on("publication", (ctx) => {
      dispatch("actionsChat", ctx.data);
      dispatch("chat/actionsChat", ctx.data, { root: true });
    });

    groupSubscription.on("error", (errCtx) => {
      console.error(`Error in group chat subscription: ${errCtx}`);
    });
  },

  actionsChat({ commit }, data) {
    switch (data.event) {
      case "App\\Events\\NotificationCounters":
        commit("SET_UNREAD_MESSAGES_COUNT", {
          countUnreadMessage: data.unread_messages_count,
        });
        break;

      case "App\\Events\\ChatMessageSent":
        commit("SET_UNREAD_MESSAGES_COUNT", {
          countUnreadMessage: data.unread_chat_count,
        });
        break;

      case "App\\Events\\SystemNotificationByTariffSent":
        commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
          countUnreadNotification: data.unread_notifications_count,
        });
        break;

      case "App\\Events\\NotificationSent":
        commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
          countUnreadNotification: data.unread_notifications_count,
        });
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
