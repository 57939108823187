import HttpClient from "@/api";
import chatService from "@/services/ChatService";
import renameField from "@/utils/renameField";
import removeTags from "@/utils/remove-tags";
import sortByDateChat from "@/utils/sort-by-date-chat";
import _trottle from "lodash/throttle";
import groupingMessages from "@/package/helpers/grouping-messages";

const state = {
  chatGroup: [],
  totalMyChats: null,
  currentChat: [],
  currentChatLinks: null,
  currentReadAtChannel: null,
  currentReadAtChannelMe: "",
  currentReadAtChannelOther: "",
  currentChatId: "",
  currentNewChatId: null,
  currentTypeChat: "",
  currentInfoChat: null,
  currentInfoThread: null,
  currentInfoUser: null,
  currentParticipantsChannel: [],
  channelTags: [],
  channelTagsLinks: null,
  currentMessage: null,
  isSubscribed: false,
  isSubscribing: false,
  isLoadingTags: false,
  isLoadingChat: false,
  isLoadingInfoUser: false,
  isLoadingInfoGroup: false,
  isLoadingDeleteGroup: false,
  isLoadingBlockUser: false,
  isLoadingSearchGroup: false,
  isLoadingSearchDirect: false,
  isLoadingChatGroup: false,
  isLoadingGroups: false,
  isLoadingDeleteCommentModerator: false,
  perPage: 15,
  currentProgress: 0,
  directContact: [],
  directOpen: false,
  groupContact: [],
  groupOpen: false,
  mineGroups: [],
  otherGroups: [],
  isNewCompanion: false,
  isOpenGroup: false,
  currentInfoGroup: null,
  threadId: null,
};

const getters = {
  sortedChatGroup(state) {
    return state.chatGroup?.sort(sortByDateChat);
  },

  sortedMineGroup(state) {
    return state.mineGroups?.sort(sortByDateChat);
  },

  sortedOtherGroup(state) {
    return state.otherGroups?.sort(sortByDateChat);
  },

  groupedMessagesByDate(state) {
    return groupingMessages(state.currentChat);
  },

  groupedThreadByDate(state) {
    return groupingMessages([...state.currentInfoThread].reverse());
  },
};

const mutations = {
  RESET_CHAT(store) {
    store.chatGroup = null;
    store.isLoading = false;
  },

  RESET_CHAT_DIRECT(store) {
    store.currentChat = [];
  },

  UPDATE_CHAT_CHANNEL(store, payload) {
    if (!store.chatGroup) {
      store.chatGroup = [];
    }
    store.chatGroup = [...store.chatGroup, ...payload.chatGroup];
    store.totalMyChats = payload.totalMyChats.total;
  },

  SET_GROUPS(store, payload) {
    store.mineGroups = payload.mineGroups;
    store.otherGroups = payload.otherGroups;
  },

  SET_MINE_GROUPS(store, payload) {
    store.mineGroups = payload;
  },

  SET_CURRENT_CHAT(store, payload) {
    store.currentChat = [...payload.currentChat];
    store.currentReadAtChannelMe = payload.currentReadAtChannelMe;
    store.currentReadAtChannelOther = payload.currentReadAtChannelOther;
    store.currentChatLinks = payload.currentChatLinks;
    store.currentChatId = payload.currentChatId;
  },

  SET_CURRENT_CHAT_ID(store, payload) {
    store.currentNewChatId = payload.currentChatId;
  },

  RESET_CURRENT_CHAT_ID(store) {
    store.currentNewChatId = null;
  },

  UPDATE_READ_AT_ME(store, payload) {
    store.currentReadAtChannelMe = payload.currentReadAtChannelMe;
  },

  UPDATE_READ_AT_OTHER(store, payload) {
    store.currentReadAtChannelOther = payload.currentReadAtChannelOther;
  },

  UNSHIFT_MESSAGE(store, payload) {
    store.currentChat.unshift(payload.message);
  },

  UNSHIFT_MESSAGE_THREAD(store, payload) {
    store.currentInfoThread.unshift(payload.message);
  },

  SWAP_MESSAGE(store, payload) {
    store.currentChat[payload.currentIndex] = payload.updateMessage;
  },

  SWAP_MESSAGE_THREAD(store, payload) {
    store.currentInfoThread[payload.currentIndex] = payload.updateMessage;
  },

  DELETE_MESSAGE(store, payload) {
    store.currentChat = store.currentChat.filter(
      (_, index) => index !== payload.currentIndex
    );
  },

  DELETE_MESSAGE_THREAD(store, payload) {
    store.currentInfoThread = store.currentInfoThread?.filter(
      (_, index) => index !== payload.currentIndex
    );
  },

  TOGGLE_LOADING_DIRECT(store) {
    store.isLoadingDirect = !store.isLoading;
  },

  TOGGLE_LOADING_GROUP(store, payload) {
    store.isLoadingGroup = payload ?? !store.isLoading;
  },

  TOGGLE_LOADING_TAGS(store, payload) {
    store.isLoadingTags = payload ?? !store.isLoading;
  },

  TOGGLE_SUBSCRIBING(store, payload) {
    store.isSubscribing = payload ?? !store.isSubscribing;
  },

  TOGGLE_LOADING_CHAT(store, payload) {
    store.isLoadingChat = payload ?? !store.isLoadingChat;
  },

  TOGGLE_LOADING_INFO_USER(store, payload) {
    store.isLoadingInfoUser = payload ?? !store.isLoadingInfoUser;
  },

  TOGGLE_LOADING_INFO_GROUP(store, payload) {
    store.isLoadingInfoGroup = payload ?? !store.isLoadingInfoGroup;
  },

  TOGGLE_LOADING_DELETE_GROUP(store, payload) {
    store.isLoadingDeleteGroup = payload ?? !store.isLoadingDeleteGroup;
  },

  TOGGLE_LOADING_BLOCK_USER(store, payload) {
    store.isLoadingBlockUser = payload ?? !store.isLoadingBlockUser;
  },

  TOGGLE_LOADING_SEARCH_GROUP(store, payload) {
    store.isLoadingSearchGroup = payload ?? !store.isLoadingSearchGroup;
  },

  TOGGLE_LOADING_SEARCH_DIRECT(store, payload) {
    store.isLoadingSearchDirect = payload ?? !store.isLoadingSearchDirect;
  },

  TOGGLE_LOADING_CHAT_GROUP(store, payload) {
    store.isLoadingChatGroup = payload ?? !store.isLoadingChatGroup;
  },

  TOGGLE_LOADING_GROUPS(store, payload) {
    store.isLoadingGroups = payload ?? !store.isLoadingGroups;
  },

  TOGGLE_LOADING_DELETE_COMMENT_MODERATOR(store, payload) {
    store.isLoadingDeleteCommentModerator =
      payload ?? !store.isLoadingDeleteCommentModerator;
  },

  SET_SUBSCRIBED(store, payload) {
    store.isSubscribed = payload ?? !store.isSubscribing;
  },

  SET_DIRECT_SEARCH(store, payload) {
    store.directContact = [...store.directContact, ...payload.directContact];
  },

  SET_GROUP_SEARCH(store, payload) {
    store.groupContact = [...store.groupContact, ...payload.groupContact];
  },

  RESET_SEARCH(store) {
    store.directContact = [];
    store.groupContact = [];
  },

  SET_INFORMATION_CURRENT_MESSAGE(store, payload) {
    store.currentMessage = payload.currentMessage;
  },

  SET_INFORMATION_CHAT(store, payload) {
    store.currentTypeChat = payload.currentTypeChat;
    store.currentInfoChat = payload.currentInfoChat;
    store.currentParticipantsChannel = payload.currentParticipantsChannel;
  },

  SET_INFORMATION_CHAT_THREAD(store, payload) {
    if (!store.currentInfoThread) {
      store.currentInfoThread = [];
    }
    store.currentInfoThread = [
      ...store.currentInfoThread,
      ...payload.currentInfoThread,
    ];
  },

  RESET_CURRENT_TYPE_CHAT(store) {
    store.currentTypeChat = "";
  },

  SET_DIRECT_TYPE_CHAT(store) {
    store.currentTypeChat = "direct";
  },

  RESET_INFORMATION_CHAT_THREAD(store) {
    store.currentInfoThread = null;
  },

  RESET_INFORMATION_CHAT(store) {
    store.currentInfoChat = null;
  },

  NEW_CHAT_COMPANION(store) {
    store.isNewCompanion = true;
  },

  OPEN_GROUP_CHANNEL(store) {
    store.isOpenGroup = true;
  },

  CLOSE_GROUP_CHANNEL(store) {
    store.isOpenGroup = false;
  },

  NEW_CHAT_COMPANION_RESET(store) {
    store.isNewCompanion = false;
    store.currentChat = [];
  },

  SET_CHANNEL_TAGS(store, payload) {
    store.channelTags = payload.channelTags;
    store.channelTagsLinks = payload.channelTagsLinks;
  },

  UPDATE_CHANNEL_TAGS(store, payload) {
    store.channelTags = [...store.channelTags, ...payload.channelTags];
    store.channelTagsLinks = payload.channelTagsLinks;
  },

  UPDATE_CHAT_DIRECT(store, payload) {
    store.chatGroup[payload.idx] = payload.item;
  },

  UPDATE_CHAT_GROUP_MINE(store, payload) {
    store.mineGroups[payload.idx] = payload.item;
  },

  UPDATE_CHAT_GROUP_OTHER(store, payload) {
    store.otherGroups[payload.idx] = payload.item;
  },

  SWAP_REPLY_AND_RESET(store, payload) {
    store.currentChat[payload.idx].thread_count = payload.counter;
    store.currentInfoThread = null;
  },

  UPDATE_REPLY_MESSAGE(store, payload) {
    store.currentChat[payload.idx].reply_to.message = payload.message;
  },

  UPDATE_REPLY_MESSAGE_THREAD(store, payload) {
    store.currentInfoThread[payload.idx].reply_to.message = payload.message;
  },

  UPDATE_CURRENT_CHAT(store, payload) {
    store.currentChat = [...store.currentChat, ...payload.currentChat];
    store.currentChatLinks = payload.currentChatLinks;
  },

  SET_INFO_USER(store, payload) {
    store.currentInfoUser = payload;
  },

  SET_INFO_GROUP(store, payload) {
    store.currentInfoGroup = payload;
  },

  SET_UPLOAD_PROGRESS(store, payload) {
    store.currentProgress = payload;
  },

  RESET_UPLOAD_PROGRESS(store) {
    store.currentProgress = 0;
  },

  UPDATE_INFO_GROUP_BAN(store, payload) {
    store.currentInfoGroup.participants = payload.participants;
    store.currentInfoGroup.blocked_participants = payload.blockedParticipants;
  },

  CHAT_THREAD_ID(store, payload) {
    store.threadId = payload.threadId;
  },

  DELETE_REPLY_IN_MESSAGES(store, payload) {
    store.currentChat.forEach((item) => {
      if (payload === item.reply_to?.id) {
        item.reply_to = null;
      }
    });
  },

  DELETE_REPLY_IN_THREADS(store, payload) {
    store.currentInfoThread.forEach((item) => {
      if (payload === item.reply_to?.id) {
        item.reply_to = null;
      }
    });
  },

  PUSH_NEW_CHAT(store, payload) {
    store.chatGroup.push(payload);
  },

  DELETE_COMMENT_MODERATOR(store) {
    store.currentInfoGroup.moderator_comment = null;
  },
};

const actions = {
  async sendMessageInChat(store, payload) {
    try {
      const {
        message,
        channelable_type,
        channelable_id,
        media,
        type,
        reply_to,
        thread_id,
      } = payload;

      const newMessage = removeTags(message);

      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.commit("SET_UPLOAD_PROGRESS", percentCompleted);
        },
      };

      const response = await HttpClient.postForm(
        "api/chat/send",
        {
          message: newMessage,
          channelable_type,
          channelable_id,
          type,
          reply_to,
          media,
          thread_id,
        },
        config
      );
      return Promise.resolve(response.data?.data?.id);
    } catch (error) {
      return Promise.reject(new Error("Не удалось отправить сообщение"));
    }
  },

  setMediaPost(store, payload) {
    const params = {
      file: payload.file,
    };

    return new Promise((resolve, reject) => {
      HttpClient.postForm(
        `api/chat/messages/${payload.message_id}/media/${payload.type}/add`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteMediaPost(_, payload) {
    const params = {
      media: payload.media,
    };
    return new Promise((resolve, reject) => {
      HttpClient.post(
        `api/chat/messages/${payload.message_id}/media/delete`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async editCurrentMessageInChat(store, payload) {
    try {
      const { message_id, addMedia, deleteMedia, message } = payload;

      if (deleteMedia.length) {
        await store.dispatch("deleteMediaPost", {
          media: deleteMedia,
          message_id: message_id,
        });
      }

      await Promise.all(
        addMedia.map((item) => {
          return store.dispatch("setMediaPost", {
            file: item.file,
            message_id: payload.message_id,
            type: item.type,
          });
        })
      );

      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.commit("SET_UPLOAD_PROGRESS", percentCompleted);
        },
      };

      return new Promise((resolve, reject) => {
        HttpClient.postForm(
          `api/chat/messages/${message_id}`,
          {
            message,
          },
          config
        )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } catch (error) {
      return Promise.reject(new Error("Не удалось отредактировать сообщение"));
    }
  },

  async showDirectChannels(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_CHAT_GROUP", true);
      const params = {
        per_page: store.perPage,
        page: payload.page,
      };
      const response = await HttpClient.get("api/chat/directs", { params });
      store.commit("UPDATE_CHAT_CHANNEL", {
        chatGroup: response.data.data,
        totalMyChats: response.data?.meta,
      });
    } catch (error) {
      return Promise.reject(
        new Error("Не удалось показать список личных чатов")
      );
    } finally {
      store.commit("TOGGLE_LOADING_CHAT_GROUP", false);
    }
  },

  async showGroupChannels(store) {
    try {
      store.commit("TOGGLE_LOADING_GROUPS", true);

      const baseUrl = "api/chat/groups?is_own=";
      const [otherGroups, mineGroups] = await Promise.all([
        HttpClient.get(baseUrl + 0),
        HttpClient.get(baseUrl + 1),
      ]);

      store.commit("SET_GROUPS", {
        otherGroups: otherGroups.data.data,
        mineGroups: mineGroups.data.data,
      });
    } catch (error) {
      return Promise.reject(
        new Error("Не удалось показать список личных чатов")
      );
    } finally {
      store.commit("TOGGLE_LOADING_GROUPS", false);
    }
  },

  async getNewCurrentChannel(store, id) {
    try {
      const response = await HttpClient.get(`api/chat/${id}`);

      if (store.state.chatGroup) {
        store.commit("PUSH_NEW_CHAT", response.data.data);
      }
    } catch (error) {
      return Promise.reject(
        new Error("Не удалось получить информацию о канале")
      );
    }
  },

  async showDirectChannelsSelectedUser(store, data) {
    try {
      store.commit("TOGGLE_LOADING_CHAT", true);

      const [resMessages, resInfoChannel] = await Promise.all([
        HttpClient.get(`api/chat/${data.id}/messages`),
        HttpClient.get(`api/chat/${data.id}`),
      ]);

      const typeChat = resInfoChannel.data.data.type;

      this.currentTypeChat = typeChat;
      if (typeChat === "direct") {
        const idCompanion = resInfoChannel.data.data.meta.id;

        const infoCompanion = resInfoChannel.data.data.participants.find(
          (item) => item.id === idCompanion
        );

        store.commit("SET_INFORMATION_CHAT", {
          currentTypeChat: typeChat,
          currentInfoChat: infoCompanion,
          currentParticipantsChannel: resInfoChannel.data.data.participants,
        });
      } else if (typeChat === "group") {
        store.commit("SET_INFORMATION_CHAT", {
          currentTypeChat: typeChat,
          currentInfoChat: resInfoChannel.data.data,
          currentParticipantsChannel: resInfoChannel.data.data.participants,
        });
      }
      store.commit("SET_CURRENT_CHAT", {
        currentChat: resMessages.data.data,
        currentReadAtChannelMe: resInfoChannel.data.data.me_read_at,
        currentReadAtChannelOther: resInfoChannel.data.data.other_read_at,
        currentChatLinks: resMessages.data.links,
        currentChatId: +data.id,
      });

      return Promise.resolve(resInfoChannel.data.data);
    } catch (error) {
      store.commit("RESET_INFORMATION_CHAT");
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING_CHAT", false);
    }
  },

  async showThreadMessage(store, data) {
    try {
      store.commit("TOGGLE_LOADING_CHAT", true);

      const resMessagesThread = await HttpClient.get(
        `api/chat/${data.channel_id}/messages/${data.message_id}`
      );

      store.commit("SET_INFORMATION_CHAT_THREAD", {
        currentInfoThread: resMessagesThread.data.data,
      });

      store.commit("CHAT_THREAD_ID", {
        threadId: data.message_id,
      });
    } catch (error) {
      store.commit("RESET_INFORMATION_CHAT");
      return Promise.reject(error);
    } finally {
      store.commit("TOGGLE_LOADING_CHAT", false);
    }
  },

  async showInfoCurrentMessage(store, data) {
    try {
      const [resMessages] = await Promise.all([
        HttpClient.get(
          `api/chat/${data.channel_id}/messages/${data.message_id}`
        ),
      ]);

      store.commit("SET_INFORMATION_CURRENT_MESSAGE", {
        currentMessage: resMessages.data.data,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(
        new Error("Не удалось показать список личных чатов")
      );
    }
  },

  async searchChannels(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_SEARCH_GROUP", true);
      const params = {
        search: payload.searchQuery,
        per_page: store.state.perPage,
        page: payload.page,
      };
      const response = await HttpClient.post(`api/chat/groups/search`, params);

      store.commit("SET_GROUP_SEARCH", {
        groupContact: response.data.data,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(
        new Error("Не удалось показать список личных чатов")
      );
    } finally {
      store.commit("TOGGLE_LOADING_SEARCH_GROUP", false);
    }
  },

  async joinGroup(store, payload) {
    try {
      const response = await HttpClient.post(
        `/api/chat/groups/${payload}/join`
      );
      response.data.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error("Не удалось подписаться на канал"));
    }
  },

  async leaveGroup(store, payload) {
    try {
      const response = await HttpClient.post(
        `/api/chat/groups/${payload}/leave`
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error("Не удалось отписаться от канала"));
    }
  },

  async searchDirect(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_SEARCH_DIRECT", true);
      const params = {
        search: payload.searchQuery,
        per_page: store.state.perPage,
        page: payload.page,
      };
      const response = await HttpClient.post(`api/chat/directs/search`, params);

      store.commit("SET_DIRECT_SEARCH", {
        directContact: response.data.data,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(
        new Error("Не удалось показать список личных чатов")
      );
    } finally {
      store.commit("TOGGLE_LOADING_SEARCH_DIRECT", false);
    }
  },

  async deleteCurrentMessage(store, data) {
    try {
      await HttpClient.delete(`api/chat/messages/${data.message_id}`);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error("Не удалось удалить сообщение"));
    }
  },

  async getBroadcastToken() {
    try {
      const response = await HttpClient.get("api/me/broadcast-token");

      return response.data.data.token;
    } catch (error) {
      return new Error("Ошибка коннекта");
    }
  },

  async getInfoChannelDirect(_, data) {
    try {
      const response = await HttpClient.get(`api/chat/directs/${data.id}`);

      return response.data.data;
    } catch (error) {
      return new Error("Канал не найден");
    }
  },

  async getCurrentUser(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_INFO_USER", true);
      const response = await HttpClient.get(`api/users/${payload}`);

      store.commit("SET_INFO_USER", response.data.data);
    } catch (e) {
      return Promise.reject(new Error("Не удалось получить пользователя"));
    } finally {
      store.commit("TOGGLE_LOADING_INFO_USER", false);
    }
  },

  async getCurrentGroup(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_INFO_GROUP", true);
      const response = await HttpClient.get(`api/chat/groups/${payload}`);

      store.commit("SET_INFO_GROUP", response.data.data);
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(new Error("Не удалось получить группу"));
    } finally {
      store.commit("TOGGLE_LOADING_INFO_GROUP", false);
    }
  },

  async postReadMark(store, data) {
    try {
      const params = {
        read_time: data.date,
      };

      await HttpClient.post(
        `api/chat/${store.state.currentChatId}/read-mark`,
        params
      );

      store.dispatch("updateChannel", store.state.currentChatId);

      store.commit("UPDATE_READ_AT_ME", {
        currentReadAtChannelMe: data.date,
      });
    } catch (error) {
      return new Error("Ошибка");
    }
  },

  async createChannel(store, payload) {
    try {
      await HttpClient.postForm("api/chat/groups", payload);
    } catch (error) {
      return Promise.reject(new Error("Не удалось создать канал"));
    }
  },

  async getTagsChannel(store, payload) {
    try {
      const params = { search: payload?.search || "" };
      const response = await HttpClient.get("api/chat/groups/tags", { params });

      const changedField = renameField(response.data.data, "name", "label");

      store.commit("SET_CHANNEL_TAGS", {
        channelTags: changedField,
        channelTagsLinks: response.data.links,
      });
    } catch (error) {
      return Promise.reject(new Error("Не удалось создать канал"));
    }
  },

  async getNextTagsChannel(store) {
    const nextUrl = store.state.channelTagsLinks.next;

    if (nextUrl) {
      try {
        store.commit("TOGGLE_LOADING_TAGS", true);
        const response = await HttpClient.get(nextUrl);

        const changedField = renameField(response.data.data, "name", "label");

        store.commit("UPDATE_CHANNEL_TAGS", {
          channelTags: changedField,
          channelTagsLinks: response.data.links,
        });
      } catch (error) {
        return Promise.reject(new Error("Не удалось загрузить ещё категорий"));
      } finally {
        store.commit("TOGGLE_LOADING_TAGS", false);
      }
    }
  },

  async checkExistenceChannel(store, payload) {
    try {
      const resInfoChannel = await HttpClient.get(
        `api/chat/directs/${payload}`
      );
      const resMessages = await HttpClient.get(
        `api/chat/${resInfoChannel.data.data.id}/messages`
      );

      const infoCompanion = resInfoChannel.data.data.participants.find(
        (item) => item.id === payload
      );

      store.commit("SET_INFORMATION_CHAT", {
        currentTypeChat: resInfoChannel.data.data.type,
        currentInfoChat: infoCompanion,
        currentParticipantsChannel: resInfoChannel.data.data.participants,
      });

      store.commit("SET_CURRENT_CHAT", {
        currentChat: resMessages.data.data,
        currentReadAtChannelMe: resInfoChannel.data.data.me_read_at,
        currentReadAtChannelOther: resInfoChannel.data.data.other_read_at,
        currentChatLinks: resMessages.data.links,
        currentChatId: +resInfoChannel.data.data.id,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteGroup(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_DELETE_GROUP", true);

      const response = await HttpClient.delete(`api/chat/groups/${payload}`);

      if (response.data.data.deleted) {
        const newMineGroups = store.state.mineGroups.filter(
          (item) => item.chat_group_id !== payload
        );

        store.commit("SET_MINE_GROUPS", newMineGroups);
      }
    } catch (error) {
      return Promise.reject(new Error("Не удалось удалить канал"));
    } finally {
      store.commit("TOGGLE_LOADING_DELETE_GROUP", false);
    }
  },

  async deleteMediaGroup(_, payload) {
    await HttpClient.post(`api/chat/groups/${payload.id}/media/delete`, {
      media: payload.idDeleteImage,
    });
  },

  async addMediaGroup(_, payload) {
    await HttpClient.postForm(`api/chat/groups/${payload.id}/media/add`, {
      file: payload.file,
      order: 0,
    });
  },

  async editGroup(store, payload) {
    try {
      const media = store.state.currentInfoGroup.chat_group_media;

      if (payload.isDeleteImage && media.length) {
        await store.dispatch("deleteMediaGroup", {
          idDeleteImage: store.state.currentInfoGroup.chat_group_media.map(
            (item) => item.id
          ),
          id: payload.id,
        });
      }

      if (payload.file) {
        await store.dispatch("addMediaGroup", {
          id: payload.id,
          file: payload.file,
        });
      }

      await HttpClient.post(`api/chat/groups/${payload.id}`, payload.data);
    } catch (error) {
      return Promise.reject(new Error("Не удалось отредактировать канал"));
    }
  },

  async blockGroupUser(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_BLOCK_USER", true);

      await HttpClient.post(
        `api/chat/groups/${payload.groupId}/user/${payload.userId}/lock`
      );

      const foundUser = store.state.currentInfoGroup.participants.find(
        (item) => item.id === payload.userId
      );

      if (foundUser) {
        const newUser = JSON.parse(JSON.stringify(foundUser));
        newUser.role = "banned";

        const newParticipants =
          store.state.currentInfoGroup.participants.filter(
            (item) => item.id !== payload.userId
          );

        const newBlockedParticipants = [
          ...store.state.currentInfoGroup.blocked_participants,
        ];

        newBlockedParticipants.push(newUser);

        store.commit("UPDATE_INFO_GROUP_BAN", {
          participants: newParticipants,
          blockedParticipants: newBlockedParticipants,
        });
      }
    } catch (error) {
      return Promise.reject(new Error("Не удалось забанить пользователя"));
    } finally {
      store.commit("TOGGLE_LOADING_BLOCK_USER", false);
    }
  },

  async unblockGroupUser(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_BLOCK_USER", true);

      await HttpClient.post(
        `api/chat/groups/${payload.groupId}/user/${payload.userId}/unlock`
      );

      const foundUser = store.state.currentInfoGroup.blocked_participants.find(
        (item) => item.id === payload.userId
      );

      if (foundUser) {
        const newUser = JSON.parse(JSON.stringify(foundUser));
        newUser.role = "member";

        const newBlockedParticipants =
          store.state.currentInfoGroup.blocked_participants.filter(
            (item) => item.id !== payload.userId
          );

        const newParticipants = [...store.state.currentInfoGroup.participants];

        newParticipants.push(newUser);

        store.commit("UPDATE_INFO_GROUP_BAN", {
          participants: newParticipants,
          blockedParticipants: newBlockedParticipants,
        });
      }
    } catch (error) {
      return Promise.reject(new Error("Не удалось разбанить пользователя"));
    } finally {
      store.commit("TOGGLE_LOADING_BLOCK_USER", false);
    }
  },

  async shutdown() {
    chatService.disconnect();
  },

  async init() {
    await chatService.connectOnChat();
    await this.dispatch("chat/listener");
  },

  async listener(store) {
    const userId = store.rootState.user.user.id;
    const centrifuge = chatService.connectionStart();
    console.log(centrifuge);

    const direct = `chats:user:direct#user::${userId}`;
    const directSubscription = centrifuge.newSubscription(direct);

    directSubscription.on("publication", (ctx) => {
      store.dispatch("actionsChat", ctx.data);
    });

    directSubscription.on("error", (errCtx) => {
      console.error(`Error in direct channel subscription: ${errCtx}`);
    });

    directSubscription.subscribe();

    const channel = `chats:user:group#user::${userId}`;
    const groupSubscription = centrifuge.newSubscription(channel);

    groupSubscription.on("publication", (ctx) => {
      store.dispatch("actionsChat", ctx.data);
    });

    groupSubscription.on("error", (errCtx) => {
      console.error(`Error in group channel subscription: ${errCtx}`);
    });

    groupSubscription.subscribe();
  },

  async updateChannel(store, payload) {
    try {
      const response = await HttpClient.get(`api/chat/${payload}`);
      const channelData = response.data.data;

      if (channelData.type === "direct") {
        const idx = store.state.chatGroup?.findIndex(
          (item) => item.id === channelData.id
        );

        if (idx !== -1 && typeof idx !== "undefined") {
          store.commit("UPDATE_CHAT_DIRECT", {
            idx,
            item: channelData,
          });
        }

        return null;
      }

      let isMineGroup = true;
      let groupIdx = store.state.mineGroups?.findIndex(
        (item) => item.id === channelData.id
      );

      if (groupIdx === -1) {
        isMineGroup = false;
        groupIdx = store.state.otherGroups?.findIndex(
          (item) => item.id === channelData.id
        );
      }

      if (groupIdx !== -1) {
        store.commit(
          isMineGroup ? "UPDATE_CHAT_GROUP_MINE" : "UPDATE_CHAT_GROUP_OTHER",
          {
            idx: groupIdx,
            item: channelData,
          }
        );
      }
    } catch (error) {
      return Promise.reject(
        new Error("Не удалось загрузить информацию о канале")
      );
    }
  },

  _throttleUpdateChannel: _trottle(({ dispatch }, payload) => {
    dispatch("updateChannel", payload);
  }, 5000),

  actionsChat(store, data) {
    switch (data.event) {
      case "App\\Events\\ChatChannelCreated":
        if (data.new_channel?.id !== null) {
          store.commit("SET_CURRENT_CHAT_ID", {
            currentChatId: data.new_channel?.id,
          });

          store.dispatch("getNewCurrentChannel", data.new_channel?.id);
          return;
        }
        break;

      case "App\\Events\\ChatMessageSent":
        console.log("yeees");
        if (data.message.chat_channel_id === store.state.currentChatId) {
          console.log("yeees2");
          if (data.message.thread_id === null) {
            store.commit("UNSHIFT_MESSAGE", {
              message: {
                ...data.message,
                thread_count:
                  store.state.currentTypeChat === "group" ? 0 : null,
              },
            });
          } else {
            store.commit("UNSHIFT_MESSAGE_THREAD", {
              message: data.message,
            });
          }
          store.dispatch(
            "_throttleUpdateChannel",
            data.message.chat_channel_id
          );

          return;
        }
        if (store.state.currentNewChatId !== null) {
          store.commit("RESET_CURRENT_CHAT_ID");
        }
        store.dispatch("updateChannel", data.message.chat_channel_id);

        break;

      case "App\\Events\\ChatMessageUpdated": {
        const findMessageIndex = store.state.currentChat.findIndex(
          (i) => i.id === data.message_updated.id
        );

        if (
          data.message_updated.chat_channel_id === store.state.currentChatId
        ) {
          store.commit("SWAP_MESSAGE", {
            updateMessage: {
              ...data.message_updated,
              thread_count:
                store.state.currentChat[findMessageIndex].thread_count,
            },
            currentIndex: findMessageIndex,
          });

          store.dispatch("updateChannel", data.message_updated.chat_channel_id);
        }
        break;
      }

      case "App\\Events\\ChatMessageDeleted": {
        const findMessageIndex = store.state.currentChat.findIndex(
          (i) => i.id === data.message_deleted.message_id
        );

        if (
          data.message_deleted.chat_channel_id === store.state.currentChatId
        ) {
          store.commit("DELETE_MESSAGE", {
            currentIndex: findMessageIndex,
          });

          store.dispatch("updateChannel", data.message_deleted.chat_channel_id);
        }
        break;
      }

      case "App\\Events\\ChatRead":
        if (data.read_mark.chat_channel_id === store.state.currentChatId) {
          store.commit("UPDATE_READ_AT_OTHER", {
            currentReadAtChannelOther: data.read_mark.read_at,
          });
        }
        break;
    }
  },
  async getNextMessages(store) {
    const nextUrl = store.state.currentChatLinks?.next;

    if (nextUrl) {
      try {
        const response = await HttpClient.get(nextUrl);

        store.commit("UPDATE_CURRENT_CHAT", {
          currentChat: response.data.data,
          currentChatLinks: response.data.links,
        });
      } catch (error) {
        return Promise.reject(
          new Error("Не удалось загрузить следующую порцию сообщений")
        );
      }
    }
  },

  async deleteCommentModerator(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_DELETE_COMMENT_MODERATOR", true);

      await HttpClient.delete(
        `api/chat/groups/${payload.group_id}/moderator-comment`
      );

      store.commit("DELETE_COMMENT_MODERATOR");
    } catch (error) {
      return Promise.reject(
        new Error("Не удалось удалить комментарий модератора")
      );
    } finally {
      store.commit("TOGGLE_LOADING_DELETE_COMMENT_MODERATOR", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
